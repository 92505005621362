import {track} from "@otto-ec/nav_star-track/src/js/star-track";
import {BrandSliderE3288} from "./brandSlider";
import {calculateTopOffset} from "./utils";
import {tabSwitchTracking} from "./tracking";

function registerElement(Elem) {
    customElements.get(Elem.customElementName) || customElements.define(Elem.customElementName, Elem);
}

export function registerMobile() {
    registerElement(MenuSheet);
    registerElement(MobileMenu);
    registerElement(MobileMenuButton);
    registerElement(MobileCluster);
    registerElement(MobileNavigationTile);
    registerElement(MobileNavigationElement);
    registerElement(MobileSheetBackButton);
    registerElement(MobileSheetTitle);
    registerElement(BrandSliderE3288);
}

export class MobileMenu extends HTMLElement {
    static customElementName = "nav-mobile-menu";
    static observedAttributes = ["shift"];
    static visibleBemModifier = "nav_mobile-menu--visible"

    constructor() {
        super();
        this.setAttribute("shift", 0);
    }

    connectedCallback() {
        let shadow = this.shadowRoot ||this.attachShadow({mode: "open"});

        /*                                                  */
        shadow.innerHTML = `
        <div class="inner-frame" style="transition: transform .3s ease, left .3s ease; position: absolute; left: 0;">
            <slot />
        </div>
        `;
        track(this, "user-action/menu-open"); /*      */
    }

    shiftRight (n) {
        this.setAttribute("shift", parseInt(this.getAttribute("shift")) + n);
    }

    resetShift () {
        this.setAttribute("shift", 0);
    }

    toggleVisibility () {
        this.classList.toggle(MobileMenu.visibleBemModifier);
    }

    attributeChangedCallback (name, oldValue, newValue) {
        if (name === "shift" && oldValue !== newValue && this.shadowRoot) {
            const element = this.shadowRoot.querySelector(".inner-frame");
            const dx = parseInt(this.getAttribute("shift")) * (-100);
            element.style.transform = `translateX(${dx}vw)`;
        }
    }
}

export class MenuSheet extends HTMLElement {
    static customElementName = "nav-menu-sheet";
    static visible = "nav_menu-sheet--visible";

    constructor() {
        super();
    }

    connectedCallback() {
        let shadow = this.shadowRoot || this.attachShadow({mode: "open"});
        shadow.innerHTML = `<slot></slot>`;
    }


    ensureLoaded (showp) {
        if (!this.getAttribute("href") || this.loaded || !showp) {
            return Promise.resolve();
        } else {
            return fetch(this.getAttribute("href"))
                .then(response => response.text())
                .then(text => this.insertAdjacentHTML("beforeend", text))
                .then(() => {
                    this.loaded = true;
                    this.querySelectorAll('nav-tab').forEach(element =>
                        element.addEventListener("click", () => tabSwitchTracking(element)));
                    const header = this.querySelector('.nav_sheet__header')
                    if (header && this.querySelector('nav-tab')) header.style.backgroundColor = "white";
                });
        }
    }

    show () {
        return this.setVisibility(true);
    }
    hide () {
        return this.setVisibility(false);
    }
    toggleVisibility () {
        return this.setVisibility(!this.classList.contains(MenuSheet.visible));
    }
    setVisibility (value) {
        return this.ensureLoaded(value)
            .then(() => this.classList.toggle(MenuSheet.visible, value));
    }
}

export class MobileMenuButton extends HTMLElement {
    static customElementName = "nav-mobile-menu-button";

    constructor() {
        super();
    }

    connectedCallback() {
        this.open = this.dataset.open || false;
        this.toggleMenu = () => {
            this.open = !this.open;
            const menuWrapper = document.getElementById(this.dataset.menuWrapper);
            if (this.open) {
                document.getElementsByTagName("body")[0].classList.add("nav__body--scrolling-disabled");
                this.classList.add("nav_menu-toggle-button--opened");
                if (menuWrapper) menuWrapper.classList.add("nav_mobile-menu-wrapper--open");

            } else {
                document.getElementsByTagName("body")[0].classList.remove("nav__body--scrolling-disabled");
                this.classList.remove("nav_menu-toggle-button--opened");
                if (menuWrapper) menuWrapper.classList.remove("nav_mobile-menu-wrapper--open");
            }

            const controlledElement = document.getElementById(this.dataset.controls);
            const menuElement = document.getElementById(this.dataset.menuRoot);

            if (menuWrapper) {
                menuWrapper.style.setProperty('--nav-menu-top-offset', calculateTopOffset(menuWrapper));
            }

            const allSheets = menuElement.querySelectorAll("nav-menu-sheet");

            allSheets.forEach(sheet => { if(sheet.hide) sheet.hide() });
            if(menuElement && menuElement.resetShift) menuElement.resetShift();
            if(menuElement && menuElement.toggleVisibility) menuElement.toggleVisibility();
            if(controlledElement && controlledElement.toggleVisibility) controlledElement.toggleVisibility();
        };
        if(!!this.dataset.controls) {
            this.addEventListener('click', () => {
                this.toggleMenu();

                if (this.open) {
                    track(this, "user-action/menu-open");
                } else {
                    track(this, "user-action/menu-close");
                    window.o_global.eventQBus.emit("ftnav.global-navigation.closed");
                }
            });
        }
        window.o_global.eventQBus.on('ftnav.global-navigation.open', () => {
            if (!this.open) {
                this.toggleMenu();
                track(this, "user-action/menu-open");
            }
        });
        window.o_global.eventQBus.on('ftnav.global-navigation.close', (opts) => {
            if (this.open) {
                this.toggleMenu();
                if (opts && opts.interaction === "button") {
                    track(this, "user-action/menu-close");
                } else {
                    track(this, "user-action/menu-exit");
                }
                window.o_global.eventQBus.emit("ftnav.global-navigation.closed");
            }
        });
    }
}

export class MobileCluster extends HTMLElement {
    static customElementName = "nav-mobile-cluster";
    constructor() {
        super();
    }
}

export class MobileNavigationTile extends HTMLElement {
    static customElementName = "nav-mobile-navigation-tile";

    constructor() {
        super();
    }

    connectedCallback() {
        let shadow = this.shadowRoot || this.attachShadow({mode: "open"});
        shadow.innerHTML = ` <slot/> `;
        this.addEventListener("click", (e) => {
            const sheet = document.getElementById(this.dataset.nextSheet);
            if (!! sheet) {
                const menu = e.target.closest("nav-mobile-menu");
                sheet.show().then(() => menu.shiftRight(1));
            }
            track(sheet, "user-action/menu-navigate-down");
        });
    }
}

export class MobileNavigationElement extends HTMLElement {
    static customElementName = "nav-mobile-navigation-element";

    constructor() {
        super();
    }

    connectedCallback() {
        let shadow = this.shadowRoot || this.attachShadow({mode: "open"});
        shadow.innerHTML = ` <slot/> `;
        this.addEventListener("click", (e) => {
            if (this.dataset.nextSheet) {
                const sheet = document.getElementById(this.dataset.nextSheet);
                track(sheet, "user-action/menu-navigate-down");
                const menu = e.target.closest("nav-mobile-menu");
                sheet.show().then(() => menu.shiftRight(1));
            } else {
                track(this, "user-action/visit-menu-link");
            }
        });
    }
}

export class MobileSheetTitle extends HTMLElement {
    static customElementName = "nav-mobile-sheet-title";

    constructor() {
        super();
    }

    connectedCallback() {
        let shadow = this.shadowRoot || this.attachShadow({mode: "open"});
        shadow.innerHTML = ` <slot/> `;
        this.addEventListener("click", (e) => {
            track(this, "user-action/visit-menu-link");
        });
    }
}

export class MobileSheetBackButton extends HTMLElement {
    static customElementName = "nav-mobile-sheet-back-button";

    constructor() {
        super();
    }

    connectedCallback() {
        this.addEventListener('click', () => {
            const menu = this.closest(MobileMenu.customElementName);
            const sheet = this.closest(MenuSheet.customElementName);
            menu.shiftRight(-1);
            setTimeout(()=> sheet.hide(), 350);

            track(sheet, "user-action/menu-navigate-up", (commands) => {
                const tabs = sheet.querySelector('nav-tabs');
                if (tabs) {
                    const selected = tabs.getAttribute("selected-tab") === "rooms" ? "raeume" : "kategorien";
                    if (commands[0].dataContainer["nav_GlobalNavigation"])
                        commands[0].dataContainer["nav_GlobalNavigation"] = commands[0].dataContainer["nav_GlobalNavigation"] + "_" + selected;
                }
                return commands;
            });
        });
    }
}
